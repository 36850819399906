<template>
  <div class="">
    <v-btn small color="" class="mr-1 border-dark px-4 py-1 " height="4vh" @click="dialog = true; resetData()">
      <v-icon class="pr-2">mdi-file-document-plus-outline</v-icon>
      <span class="text-capitalize">Bulk Upload</span>
    </v-btn>
    <lb-drawer v-model="dialog" :Maxwidth="(uploaddata.length > 0) ? '1665' : '700'"
      heading="Bulk Upload" :loading="loading">
      <template v-slot:body>
        <div>
          <div class="text-center mb-1 d-flex justify-space-between align-center" >
            <p class="ma-0 pa-0" v-if="uploaddata.length === 0" >Upload your document</p>
            <p class="ma-0 pa-0 font-weight-bold" v-else>Preview</p>
            <div class="d-flex align-center justify-center">
              <v-btn color="white" v-if="uploaddata.length>0 || reUpload"  small class="ml-2 d-flex align-center  justify-space-between "
                @click="reuploadDoc()"> <v-icon class="pr-1">mdi-reload</v-icon> <p class="ma-0 pa-0"> Re-Upload</p></v-btn>
              <v-btn  small class="border-dark ml-2 d-flex align-center  justify-space-between "
              @click="$nova.downloadFile('', {}, '/v2/financialclose/masters/informationmanager/getbulksubmittemplate')"> <v-icon class="pt-1 pr-1">mdi-tray-arrow-down</v-icon> <p class="ma-0 pa-0"> Download Template</p></v-btn>
            </div>
          </div>
          <div class="" v-if="uploaddata.length>0 " >
            <v-checkbox @change="dataOnlyWithError=!dataOnlyWithError" label="Show only items with errors" class="my-1 mb-6" color="caccounting" hide-details></v-checkbox>
          </div>
          <div class="d-flex align-center justify-center mt-4" v-if="uploaddata.length === 0">
              <DragDropfile hidedetails :files="filesbulk" @update:files="updateFilesBulk"  />
          </div>
        </div>
        <div v-if="uploaddata.length > 0" class="mt-3  radius-small">
          <v-simple-table dense class="radius-small border-on simple-table " style="max-height:73vh; overflow:scroll">
            <template v-slot:default>
              <thead class="grayBackground" >
                
                <th v-for="(item,index) in tableheader" :key="index" class="text-left text-capitalize  pa-2 border-right-data grayBackground" :scope="item.value">{{ item.name }}</th>
                
              </thead>
              <v-slide-y-transition class="" group tag="tbody" style="overflow:hidden !important;">
                <tr v-for="(v, k) in filteredData" :key="k" class="">
                  <td>{{k+1}}</td>
                  <template v-for="(vv, kk) in v">
                    <td v-if="errorsonly[k][kk]" :key="kk" class="text-caption pa-0 font-weight-bold border-right-data" >
                      <div class="all-width all-height" style="border: 1px solid #F3B9BA">

                          <div v-if=" kk==='location' && vv" class="">
                            <p small outlined class=" d-flex bor pl-2 mb-1 align-center mr-1">
                              <span class="pa-1 caccounting--text purpleborder radius-mini  two-line-clamp">{{ vv }}</span>
                            </p>
                          </div>
                          <div v-else-if=" kk==='checklistref' && vv" class="d-flex">
                            <template v-for="(item,index) in vv">
                                  <p :key="index" small outlined class=" d-flex bor pl-2 mb-1 align-center mr-1">
                                  <span class="pa-1 caccounting--text purpleborder radius-mini  two-line-clamp">{{ item }}</span>
                                </p>
                            </template>
                          </div>
                         
                          <div v-else class="two-line-clamp">{{errorsonly[k][kk]}}</div>
                        </div>
                    </td>
                    <td v-else :key="kk" class="border-right-data">

                      <div v-if="kk==='location' && vv" class="">
                        <p   small outlined class=" d-flex bor pl-2 my-1 align-center mr-1">
                          <span class="pa-1 caccounting--text purpleborder radius-mini  two-line-clamp">{{ vv }}</span>
                        </p>
                      </div>
                      <div v-else-if=" kk==='checklistref' && vv" class="d-flex">
                        <template v-for="(item,index) in vv">
                              <p :key="index" small outlined class=" d-flex bor pl-2 mb-1 align-center mr-1">
                              <span class="pa-1 caccounting--text purpleborder radius-mini  two-line-clamp">{{ item }}</span>
                            </p>
                        </template>
                      </div>
                    
                      <div v-else class="two-line-clamp">{{ vv }}</div>
                    </td>
                  </template>
                </tr>
              </v-slide-y-transition>
            </template>
          </v-simple-table>
        </div>
        <p v-if="dataerror.length>0" class="error--text ma-0 pa-0 mt-4 text-caption">*These are fields where errors exist/ information is missing. Please rectify and re-upload to continue.</p>
      </template>
      <template v-slot:actions>
        <div class="">
        </div>
        <v-spacer></v-spacer>
        <v-btn class="border-dark" v-if="uploaddata.length > 0" @click="dialog = false; resetData()">Cancel</v-btn>
       <v-btn color="black"  @click="uploadData" class="white--text" >Upload</v-btn>
      </template>
    </lb-drawer>
  </div>
</template>

<script>
import DragDropfile from '../../../../views/Inputs/DragDropfile.vue';

export default {
  name: 'financialclose_component_checklist_bulkupload',
  // props: ['props'],
  data: function () {
    return {
      right: "financialclose_master_checklist",
      // generateddescription: "",
      file: null,
      fileprocessing: {},
      displaycolumns: ['type', 'category', 'description', 'info_owner_name', 'info_owner_email', 'urgent','checklistref','location','duedate'],
      flag: false,
      uploaddata: [],
      dataerror: [],
      dialog: false,
      loading: false,
      filesbulk: [],
      reUpload:false,
      tableheader:[
        {
          name:"S No",
          value:"sno"
        },
        {
          name:"Request Information",
          value:"requested_info"
        },
        {
          name:"Type",
          value:"type"
        },
        {
          name:"Classification",
          value:"classification"
        },
        {
          name:"Requested From",
          value:"info_owner_name"
        },
        {
          name:"Email ID",
          value:"info_owner_email"
        },
        {
          name:"Tasks Linked",
          value:"checklistref"
        },
        {
          name:"Location",
          value:"location"
        },
        {
          name:"Priority",
          value:"priority"
        },
        {
          name:"Due Days",
          value:"duedate"
        }
      ],
      dataOnlyWithError:false,
      dataWithNoErros:[],
      errorsonly:[],
    }
  },
  created() {
  },
  components:{
    DragDropfile
  },
  activated() {
  },
  methods: {
    init() {
    },
    readFile(key) {
      let file = this.filesbulk[0];
      if ((file || {}).name && (this.fileprocessing[key] || 0) === 0) {
        let headings = [ 'Request Information', 'Type', 'Classification', 'Requested From', 'Email ID', 'Tasks Linked',"Location",'Priority', 'Due Days'];
        this.loading = true;
        this.fileprocessing[key] = 1;
        let ext = ((file || {}).name || "").split(".").pop();
        Promise.resolve().then(() => {
          if (["xlsx", "xls", "xlsm", "xlsb"].indexOf(ext) === -1) throw new "Only xlsx,xls,xlsm,xlsb file extension supported";
          return this.$nova.readExcelFile(file, "TOP SHEET");
        }).then(dt => {
          if (JSON.stringify(dt[0] || []) === JSON.stringify(headings)) {
            let filedata = [];
            let errordata = [];
            this.flag = false;
            for (let i = 0; i < dt.length; i++) {
              console.log("qwer5tyu",i);
              if (i > 0 && dt[i].length>0) {
                const el = dt[i];
                let rowdata = { 
                  "requested_info": el[0], 
                  "type": el[1], 
                  "classification": el[2], 
                  "info_owner_name": el[3], 
                  "info_owner_email": el[4], 
                  "checklistref": (el[5] || "").split(",").filter(x => x.replaceAll(/\s/g, '')),
                  "location": el[6], 
                  "priority":el[7],
                  "duedate":el[8]  };
                let errors = {};
                if ((rowdata.classification || "").toString() === "") errors["classification"] = " ";
                if ((rowdata.requested_info || "").toString() === "") errors["requested_info"] = " ";
                if ((rowdata.info_owner_name || "").toString() === "") errors["info_owner_name"] = " ";
                if ((rowdata.info_owner_email || "").toString() === "") errors["info_owner_email"] = " ";
                if (Object.keys(errors).length > 0) this.flag = true;
                filedata.push(rowdata);
                errordata.push(errors);
              }
            }
            this.uploaddata = filedata;
            this.dataerror = errordata;
            this.dataWithNoErros=filedata 
            this.errorsonly=errordata           
            this.dialog = true;
          } else throw Error("Columns '" + headings.join("','") + "' are required in the same order");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
          this.uploaddata=[];
          this.filesbulk=[];
          this.reUpload=true
        }).finally(() => {
          this.file = null;
          this.fileprocessing[key] = 0;
          this.loading = false;
        });
      }
    },
    uploadData() {
      this.loading = true;
      
      this.axios.post("/v2/financialclose/masters/informationmanager/bulkadd", { data: this.dataWithNoErros }).then(dt => {
        if (dt.data.status === "success") {
          this.dialog = false;
          this.$emit('reload');
          this.$store.commit("sbSuccess", "Information Requirement uploaded successfully");
        } else throw dt.data.message || "Error uploading Information Requirement";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateFilesBulk(newFilesBulk) {
      this.filesbulk = newFilesBulk;
      this.readFile('file')
      this.reUpload=true

   },
   reuploadDoc(){
    this.uploaddata=[]
    this.dataerror=[];
   },
   reuploadfile(event){
    this.uploadData=[]
    this.filesbulk= event.target.files
    this.readFile('file')
   },
   resetData(){
    this.uploaddata= [];
    this.reUpload=false ;
    this.file = null; 
    this.dataerror= [];
   },
  },
  computed:{

    filteredData() {
      if (this.dataOnlyWithError) {
      return this.uploaddata.filter((_, rowIndex) =>
          Object.values(this.dataerror[rowIndex]).some((error) => error)
        );
    }
    return this.uploaddata; // Return all rows if not filtering errors
      }
  },
  watch: {
    dataOnlyWithError(newVal) {
    if (newVal) {
      this.errorsonly = this.dataerror.filter(item => Object.keys(item).length > 0);
    } else {
      this.errorsonly = this.dataerror;
    }
  }
  }
}
</script>
